// - - - - - - - - - - Foundations

@import "_reset";
@import "_mixins";
@import "_plugins";



// - - - - - - - - - - Variables


// Colors

$background-color: #ffffff;
$background-alt-color: #F5F7FA;
$border-color: #dddddd;

$text-dark-color: #2A2F36;
$text-medium-color: #6C7A89;
$text-light-color: #ABB7B7;

$accent-color: #A2DED0;

$overlay-background-color: #161B21;
$overlay-text-color: #ffffff;

$error-color: #D64541;


// Fonts

$body-font: "Muli", sans-serif;
$title-font: "Merriweather", sans-serif;

$regular-weight: 400;
$bold-weight: 600;


// Typography

$sitetitle-small: 25px;
$sitetitle-medium: 30px;
$sitetitle-large: 35px;
$sitetitle-weight: 400;
$sitetitle-spacing: em;

$sitetagline-small: 16px;
$sitetagline-medium: 17px;
$sitetagline-large: 18px;
$sitetagline-weight: 400;
$sitetagline-spacing: em;
$sitetagline-lineheight: 1.5;

$menu-small: 30px;
$menu-medium: 17px;
$menu-large: 18px;
$menu-weight: 400;
$menu-spacing: em;

$h1-small: 25px;
$h1-medium: 35px;
$h1-large: 45px;
$h1-weight: 400;
$h1-spacing: -0.02em;
$h1-lineheight: 1.2;

$h2-small: 25px;
$h2-medium: 35px;
$h2-large: 45px;
$h2-weight: 400;
$h2-spacing: -0.02em;
$h2-lineheight: 1.2;

$h3-small: 22px;
$h3-medium: 25px;
$h3-large: 30px;
$h3-weight: 400;
$h3-spacing: -0.02em;
$h3-lineheight: 1.3;

$h4-small: 17px;
$h4-medium: 22px;
$h4-large: 22px;
$h4-weight: 400;
$h4-spacing: em;
$h4-lineheight: 1.6;

$h5-small: 17px;
$h5-medium: 20px;
$h5-large: 20px;
$h5-weight: 400;
$h5-spacing: em;
$h5-lineheight: 1.6;

$h6-small: 17px;
$h6-medium: 20px;
$h6-large: 20px;
$h6-weight: 400;
$h6-spacing: em;
$h6-lineheight: 1.6;

$p-small: 16px;
$p-medium: 18px;
$p-large: 20px;
$p-weight: 400;
$p-spacing: em;
$p-lineheight: 1.6;

$blockquote-small: 25px;
$blockquote-medium: 35px;
$blockquote-large: 45px;
$blockquote-weight: 400;
$blockquote-spacing: -0.02em;
$blockquote-lineheight: 1.6;

$button-weight: 600;
$button-spacing: em;


// Header
$overlay-opacity: 0.7;


// Logo
$logo-width: 200px;
$footer-logo-width: px;


// Grid item
$grid-spacing: 10px;



// - - - - - - - - - - Global styles

@import "_basic";

@import "_includes/_header";
@import "_includes/_footer";

@import "_includes/_content";
@import "_includes/_socials";
@import "_includes/_gallery";
@import "_includes/_syntax";
@import "_includes/_contact-form";
@import "_includes/_webmentions";


// - - - - - - - - - - Section styles

@import "_includes/_portfolio";
@import "_includes/_blog";
